import React, { ReactElement } from 'react'
import {
    Description,
    Grid,
    GridSeparator,
    MainContent,
    SideContent,
    Title,
  } from './styles';

/**
 * JarvisPortalPage Properties
 */
type JarvisPortalPageProps = {
  /** The title to display above the component */
  title: string

  /** The Subtitle to display above the component */
  description: string

  /** The main react element to render on the page*/
  mainContent: ReactElement
  
  /** The react element to render on the side of the page*/
  sideContent?: ReactElement

  /** Direction to display content */
  direction: string

  /** Whether or not to use the grid layout */
  useGrid?: boolean
}

/**
* React component that renders a UCDE Style Page.
* @component
* @param {JarvisPortalPageProps} props The react props
*/
export const JarvisPortalPage = (props: JarvisPortalPageProps) => {
  if (props.useGrid === undefined || props.useGrid) {
    return (
        <div dir={props.direction}>
            <GridSeparator>
                <Title>{props.title}</Title>
                <Description>{props.description}</Description>
            </GridSeparator>
            <Grid>
                <MainContent>
                    {props.mainContent}
                </MainContent>
                <SideContent>
                    {props.sideContent}
                </SideContent>
            </Grid>
        </div>
    )
  } else {
    return (
      <div dir={props.direction}>
          <Title>{props.title}</Title>
          <Description>{props.description}</Description>
          {props.mainContent}
      </div>
    )
  }
}