import React from "react";
import { JarvisPortalFooterType } from "./types";
import {
  LeftArea,
  RightArea,
  PortalFooter,
  FooterItem,
  RightFooterItemsContent,
} from "./styles";

export const JarvisPortalFooter: React.FC<JarvisPortalFooterType> = (props) => {
  const { leftContent, rightContent } = props;
  if (Array.isArray(rightContent)) {
    return (
      <PortalFooter
        mainContent={{
          leftArea: (
            <LeftArea>
              {leftContent || "© Copyright. All rights reserved."}
            </LeftArea>
          ),
          rightArea: (
            <RightFooterItemsContent>
              {rightContent.map((item) => (
                <FooterItem>{item}</FooterItem>
              ))}
            </RightFooterItemsContent>
          ),
        }}
        appearance="ultraminimal"
      />
    );
  }

  return (
    <PortalFooter
      mainContent={{
        leftArea: (
          <LeftArea>
            {props.leftContent || "© Copyright. All rights reserved."}
          </LeftArea>
        ),
        rightArea: (
          <RightArea>
            {props.rightContent || "English (United States)"}
          </RightArea>
        ),
      }}
      appearance="ultraminimal"
    />
  );
};
