import React from 'react';

import * as S from './styles';
import { JarvisPortalTabContentType } from './types';

export const JarvisPortalTabContent: React.FC<JarvisPortalTabContentType> = (props) => {
  const {children, title, actionComponents} = props;

  return <S.Container>
    {(title || actionComponents) && 
      <S.TopDivContent>
        {title && 
          <S.TitleDivContent>
            {typeof title === 'string' ? <S.Title>{title}</S.Title> : title}
          </S.TitleDivContent>
        }
        {actionComponents && 
          <S.ActionComponentsDivContent>
            {actionComponents}
          </S.ActionComponentsDivContent>
        }
      </S.TopDivContent>
    }
    <S.MainContentDiv>
      {children}
    </S.MainContentDiv>
  </S.Container>;
}