import styled from "styled-components";
import VeneerCard from "@veneer/core/dist/scripts/card/card";
import tokens from "@veneer/tokens";

export const Card = styled(VeneerCard)`
  width: 100%;
  max-width: 100%;
  height: 100%;

  && {
    border-radius: 8px;
    box-sizing: border-box;
  }
`;

export const CardBody = styled.div`
  padding: 0 ${tokens.size5} ${tokens.size3};
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${tokens.colorGray1};
`;

export const Title = styled.h1`
  font-size: ${tokens.size5};
  line-height: 1.2;
  margin-right: auto;
`;

export const TitleCounter = styled.span`
  font-size: ${tokens.size5};
  line-height: 1.2;
  margin-right: auto;
  color: ${tokens.colorGray5};
  padding: 0 ${tokens.size1};
`;

/**
 * Widget content separator. Adds vertical padding and a bottom margin
 */
export const Section = styled.section`
  padding: 16px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${tokens.colorGray1};
  }
`;

/**
 * Main body text used on Widgets
 */
export const BodyText = styled.p`
  font-size: 14px;
  line-height: 1.1;
  font-weight: 700;
`;

/**
 * Main anchor tag style for Widgets
 */
export const Link = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: 900;
  color: ${tokens.colorHpBlue4};
  margin-right: 5px;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${tokens.colorHpBlue6};
  }
`;
