import { JarvisAuthProvider } from '@jarvis/web-http'
import { AssetsProvider } from '@jarvis/web-assets-provider'
import AssetsProviderFactory from '../assets/AssetsProviderFactory'
import { Stack } from '@jarvis/web-stratus-client'

type ManageDeviceProps = {
	country?: string
	language?: string
	authProvider: JarvisAuthProvider
	stack: Stack
}
let localprops: ManageDeviceProps = {
	language: 'en',
	country: 'US',
	authProvider: null,
	stack: null,
}

export const setProps = (props) => {
	localprops = props
}

export const getProps = (): ManageDeviceProps => {
	return localprops
}

export const getText = (subkey: string): string => {
	const props = getProps()
	const assetsProvider: AssetsProvider = AssetsProviderFactory.create(
		props.language,
		props.country,
	)
	const assetKey = `${subkey}`
	return assetsProvider.getText(assetKey)
}

export const convertLastSyncDateAndTime = (date_time) => {
	const today = new Date(date_time)
	const locale: string = 'en'
	const dateAndTime = `${today.getDate()} ${today.toLocaleDateString(locale, {
		month: 'short',
	})} ${today.toLocaleDateString(locale, {
		year: 'numeric',
	})} | ${today.toLocaleTimeString(locale, {
		hour: 'numeric',
		hour12: true,
		minute: 'numeric',
	})}`
	return dateAndTime
}

export const getDateAndTime = (language = 'en', country = 'us') => {
	const today = new Date()
	const locale: string = `${language}-${country.toUpperCase()}`
	const dateAndTime = `${today.getDate()}
                ${today.toLocaleDateString(locale, { month: 'short' })}
                ${today.toLocaleDateString(locale, {
									year: 'numeric',
								})} | ${today.toLocaleTimeString(locale, {
		hour: 'numeric',
		hour12: true,
		minute: 'numeric',
		second: 'numeric',
	})}`
	return dateAndTime
}
