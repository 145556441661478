import { useState, useEffect } from 'react'
import { getEnvConfig } from '../utils/constants'
import ReleaseIntentClient from '../client/ReleaseIntentClient'

export const useReleaseIntent = ({ stack, authProvider }) => {
	const [clientReleaseIntent, setClientReleaseIntent] = useState([])
	const [fetchingReleaseIntent, setFetchingReleaseIntent] = useState(true)
	const [errorToFetchReleaseIntent, setErrorToFetchReleaseIntent] =
		useState(false)

	const config = getEnvConfig(stack)

	const fetchReleaseIntent = async () => {
		setFetchingReleaseIntent(true)
		setErrorToFetchReleaseIntent(false)
		const releaseIntent = new ReleaseIntentClient(
			config.apiBaseUrl,
			authProvider,
		)
		try {
			let releaseIntentResult = await releaseIntent.getClientReleaseIntent()

			setClientReleaseIntent(releaseIntentResult)
		} catch (error) {
			setErrorToFetchReleaseIntent(true)
		} finally {
			setFetchingReleaseIntent(false)
		}
	}
	useEffect(() => {
		fetchReleaseIntent()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		fetchReleaseIntent,
		clientReleaseIntent,
		fetchingReleaseIntent,
		errorToFetchReleaseIntent,
	}
}
