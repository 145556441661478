import { useState, useEffect, useContext } from 'react'
import {
	getEnvConfig,
	SET_NON_OWNER,
	SET_ROAM_QUEUE_ENABLED,
} from '../utils/constants'
import UserClient from '../client/UserClient'
import AppContext from '../context/AppContext'

export const useUser = ({ stack, authProvider }) => {
	const { userDispatch } = useContext(AppContext)
	const [isAdmin, setIsAdmin] = useState(null)
	const [roamQueueEnabled, setRoamQueueEnabled] = useState(null)
	const [errorToFetchUserAndRoles, setErrorToFetchUserAndRoles] =
		useState(false)
	const [fetchingUserAndRoles, setFetchingUserAndRoles] = useState(true)
	const config = getEnvConfig(stack)

	const fetchUser = async () => {
		setFetchingUserAndRoles(true)
		setErrorToFetchUserAndRoles(false)
		const userClient = new UserClient(config.apiBaseUrl, authProvider)
		try {
			let result = await userClient.getUser()
			let accountId = result.accountId
			let roles = await userClient.getRoles(result.id)
			let rolesArray = roles.content.map((obj) => obj['roleName'])
			let isAdmin = rolesArray.includes('ADMIN')
			let accountResult = await userClient.getAccount(accountId)
			setIsAdmin(isAdmin)
			setRoamQueueEnabled(accountResult.roamQueueEnabled)
			userDispatch({ type: SET_NON_OWNER, payload: { nonOwner: !isAdmin } })
			userDispatch({
				type: SET_ROAM_QUEUE_ENABLED,
				payload: { roamQueueEnabled: true },
			})
		} catch (error) {
			setErrorToFetchUserAndRoles(true)
		} finally {
			setFetchingUserAndRoles(false)
		}
	}

	useEffect(() => {
		fetchUser()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		fetchUser,
		errorToFetchUserAndRoles,
		fetchingUserAndRoles,
	}
}
