import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
`;

export const TopDivContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  max-width: 100%;
  margin: 1rem 0;
`;

export const TitleDivContent = styled.div`
  width: 40%;
`;

export const Title = styled.h3`
  font-size: 17px;
  line-height: 20px;
  color: #737373;
  margin-left: 0.5rem;
`;

export const ActionComponentsDivContent = styled.div`
    width: 60%;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
`;

export const MainContentDiv = styled.div`
    max-width: 100%;
`;
