/**
 * From ucde-portal/src/pages/styles.js
 */
 import tokens from "@veneer/tokens"
export const UCDEMainTheme = {
    breakPoints: {
      sm: '375px',
      md: '600px',
      lg: '1036px',
      xg: '1280px',
    },
    text: {
      mobile: {
        h1: '24px',
        fontSize: '14px',
      },
      desktop: {
        h1: '40px',
        fontSize: '16px',
      },
    },
    main: {
      cartridge: {
        cyan: '#03a4f0',
        magenta: '#f7448b',
        yellow: '#ffd906',
        black: '#000000',
        borderLow: '#e8701a',
        borderVeryLow: '#d0021b',
        borderNone: 'transparent',
      },
      cartridgeLarge: {
        cyan: { color: '#03a4f0', borderColor: '#70707000' },
        magenta: { color: '#f7448b', borderColor: '#70707000' },
        yellow: { color: '#ffd906', borderColor: '#70707000' },
        black: { color: '#000000', borderColor: '#70707000' },
        lightGray: { color: '#bbbbbb', borderColor: '#70707000' },
        darkGray: { color: '#303030', borderColor: '#70707000' },
        red: { color: '#be3754', borderColor: '#70707000' },
        green: { color: '#1ab77c', borderColor: '#70707000' },
        white: { color: '#ffffff', borderColor: '#4e4e4e' },
      },
      benefits: {
        textColor: '#4E4E4E',
        lineHeight: '22px',
      },
      hp: {
        color: '#027AAE',
        lightColor: '#F2F8FB',
        fontColor: '#464D50',
        fontFamily: tokens.fontFamilyRegular,
        fontFamilyLight: tokens.fontFamilyLight,
        fontSize: '16px',
      },
      content: {
        background: '#F8FAFB',
      },
    },
    sideBar: {
      fontColor: '#919191',
      icon: {
        color: '#919191',
      },
    },
    topBar: {
      icon: {
        color: '#404040',
      },
    },
  }; 