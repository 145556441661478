import styled from 'styled-components';
import { rules4OldIEs } from './mixins';
import { stylesIf } from './styleHelpers';
import { UCDEMainTheme } from './ucdeTheme'


/*
 * Base components for pages
 */
export const Title = styled.h4`
  flex-basis: 100%;
  font-size: 40px;
  font-family: ${UCDEMainTheme.main.hp.fontFamilyLight};
  word-break: break-word;
  line-height: 0.75;
  margin-bottom: 10px;
  padding-top: 20px;
`;

export const Description = styled.div`
  font-family: ${UCDEMainTheme.main.hp.fontFamilyLight};
  font-size: 16px;
  margin-bottom: ${stylesIf('noMargin', '0', '32px')};
  max-width: 660px;
`;

/*
 * Grid Layout Components: used in dashboard / pages
 */
export const Grid = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 640px 300px;
  -ms-grid-columns: 640px 300px;
  grid-gap: 32px 32px;
  margin: ${stylesIf('noMargin', '0', '32px 0')};
  @media (max-width: ${UCDEMainTheme.breakPoints.lg}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${UCDEMainTheme.breakPoints.xg}) {
    grid-template-columns: 1fr;
    -ms-grid-column-span:  1fr;
  }
`;

export const GridSeparator = styled.div`
  max-width: 975px;
  width: 100%;
  @media (max-width: ${UCDEMainTheme.breakPoints.lg}) {
    width: inherit;
    flex: 1;
  }
`;

export const SideContent = styled.div`
  ${rules4OldIEs(`
    margin-left: 32px;
  `)}
  > * {
    &:not(:last-child)  {
      margin-bottom: 32px;
    }
  }
`;

export const MainContent = styled.div`
  > * {
    &:not(:last-child)  {
      :not(:empty) {
        margin-bottom: 32px;
      }
    }
  }
`;