import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  min-height: fit-content;
  box-sizing: border-box;
  max-width: 100%;
  padding: 24px 24px 32px;

  & > * {
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const GridSeparator = styled.div`
  max-width: 100%;
  
  & > * {
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const TitleContent = styled.div`
  max-width: 100%;

  & > * {
    max-width: 100%;
    
    &:first-child:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const Title = styled.h4`
  flex-basis: 100%;
  font-size: 40px;
  font-family: HP Simplified Light;
`;

export const Description = styled.div`
  font-family: HP Simplified Light;
  font-size: 16px;
`;

/*
 * Grid Layout Components: used in dashboard / pages
 */
export const Grid = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 640px 300px;
  -ms-grid-columns: 640px 300px;
  grid-gap: 32px 32px;

  @media (max-width: 1036px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    -ms-grid-column-span:  1fr;
  }
`;

export const MainContent = styled.div`
  max-width: 100%;

  > * {
    &:not(:last-child)  {
      :not(:empty) {
        margin-bottom: 32px;
      }
    }
  }
`;