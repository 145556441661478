import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Content = styled.div`
	position: relative;
	background: ${tokens.color.gray0};
	.IconRelodColor {
		fill: ${tokens.color.hpBlue6} !important;
	}
`

export const DeviceTitleSection = styled.div`
	max-width: 100%;
	margin-bottom: 8px !important;
`

export const DeviceTitle = styled.h2`
	font-size: 32px;
	line-height: 36px;
	flex-basis: 100%;
	margin-bottom: 16px;
`
export const DeviceSubTitle = styled.p`
	font-size: 16px;
	line-height: 24px;
`
