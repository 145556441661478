import { JarvisWebHttpClient } from '@jarvis/web-http'
import {
	userFetchError,
	userRoleFetchError,
	userAccountFetchError,
} from '../utils/constants'

class UserClient {
	baseUrl
	jarvisWebClient

	constructor(baseUrl, jarvisAuthProvider) {
		this.baseUrl = baseUrl

		this.jarvisWebClient = new JarvisWebHttpClient({
			host: this.baseUrl,
			defaultAuthProvider: jarvisAuthProvider,
		})
	}

	async getUser() {
		const response = await this.jarvisWebClient.get({
			url: this.userEndpoint(),
		})
		if (this.isStatusCodeOk(response.status)) {
			if (response.data) {
				return response.data
			}
			return []
		} else {
			throw new Error(userFetchError)
		}
	}

	async getRoles(userId) {
		const response = await this.jarvisWebClient.get({
			url: this.userRolesEndpoint(userId),
		})
		if (this.isStatusCodeOk(response.status)) {
			if (response.data) {
				return response.data
			}
			return []
		} else {
			throw new Error(userFetchError)
		}
	}

	async getAccount(accountId) {
		const response = await this.jarvisWebClient.get({
			url: this.userAccountEndpoint(accountId),
		})
		if (this.isStatusCodeOk(response.status)) {
			if (response.data) {
				return response.data
			}
			return []
		} else {
			throw new Error(userAccountFetchError)
		}
	}

	userEndpoint() {
		return `${this.baseUrl}/api/v1/user/self`
	}

	userRolesEndpoint(userId) {
		return `${this.baseUrl}/api/v1/role/?userId=${userId}`
	}

	userAccountEndpoint(accountId) {
		return `${this.baseUrl}/api/v1/account/${accountId}`
	}

	isStatusCodeOk(status) {
		return status > 199 && status < 400
	}
}

export default UserClient
