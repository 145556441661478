import {
	FETCH_PRINTERS,
	FETCH_PRINTERS_SUCCESS,
	SET_PA_PRINTERS,
	SET_ROAM_PRINTERS,
	UPDATE_PA_PRINTER_DATA,
	UPDATE_PA_PRINTER_FEATURE_LOADING,
	UPDATE_ROAM_PRINTER_DATA,
	UPDATE_ROAM_PRINTER_FEATURE_LOADING,
	SET_NON_OWNER,
	SET_ROAM_QUEUE_ENABLED,
} from '../utils/constants'

// const USER_INITIAL_STATE = {
// 	nonOwner: false,
//  roamQueueEnabled: false,
// }
const userReducer = (state, action) => {
	switch (action.type) {
		case SET_NON_OWNER:
			return {
				...state,
				nonOwner: action.payload.nonOwner,
			}

		case SET_ROAM_QUEUE_ENABLED:
			return {
				...state,
				roamQueueEnabled: action.payload.roamQueueEnabled,
			}

		default:
			return state
	}
}

/* printers state has this structure:
   {
       loading: boolean,
       printers: [],
       error: null       
   }
   
*/
// const PRINTERS_INITIAL_STATE = {
// 	loading: false,
// 	printers: [],
// 	error: null,
// 	featuresLoading: {},
// }
const printersReducer = (state, action) => {
	switch (action.type) {
		case FETCH_PRINTERS:
			return {
				...state,
				loading: true,
				error: null,
			}
		case FETCH_PRINTERS_SUCCESS:
			return {
				...state,
				printers: action.payload.printers,
				loading: false,
				error: null,
			}

		default:
			return state
	}
}

/* Print Anywhere printers state has this structure:
   {
       loading: boolean,
       printers: [],
       error: null
       featuresLoading : {
           <printerID>: {printOnTheGoEnabled: false, preferredReleaseIntent: false},
           <printerID>: {printOnTheGoEnabled: false, preferredReleaseIntent: false}
       }
   }
   
*/
// const PA_PRINTERS_INITIAL_STATE = {
// 	loading: false,
// 	printers: [],
// 	error: null,
// 	featuresLoading: {},
// }
const paPrintersReducer = (state, action) => {
	switch (action.type) {
		case SET_PA_PRINTERS:
			return {
				...state,
				printers: action.payload.printers,
				loading: false,
				error: null,
				featuresLoading: action.payload.printers.reduce(
					(acc, printer, index) => ({
						...acc,
						[printer.id]: {
							printOnTheGoEnabled: false,
							preferredReleaseIntent: false,
						},
					}),
					{},
				),
			}

		case UPDATE_PA_PRINTER_DATA:
			return {
				...state,
				printers: state.printers.map((printer) =>
					printer.id === action.payload.printerId
						? { ...printer, ...action.payload.data }
						: printer,
				),
			}
		case UPDATE_PA_PRINTER_FEATURE_LOADING:
			return {
				...state,
				featuresLoading: {
					...state.featuresLoading,
					[action.payload.printerId]: {
						...state.featuresLoading[action.payload.printerId],
						[action.payload.featureName]: action.payload.loading,
					},
				},
			}
		default:
			return state
	}
}

/* ROAM printers state has this structure:
   {
       loading: boolean,
       printers: [],
       error: null
       featuresLoading : {
           <printerID>: {roamQueuePrinting: false},
           <printerID>: {roamQueuePrinting: false}
       }
   }
   
*/
// const ROAM_PRINTERS_INITIAL_STATE = {
// 	loading: false,
// 	printers: [],
// 	error: null,
// 	featuresLoading: {},
// }
const roamPrintersReducer = (state, action) => {
	switch (action.type) {
		case SET_ROAM_PRINTERS:
			return {
				...state,
				printers: action.payload.printers,
				loading: false,
				error: null,
				featuresLoading: action.payload.printers.reduce(
					(acc, printer, index) => ({
						...acc,
						[printer.id]: {
							roamQueuePrinting: false,
						},
					}),
					{},
				),
			}

		case UPDATE_ROAM_PRINTER_DATA:
			return {
				...state,
				printers: state.printers.map((printer) =>
					printer.id === action.payload.printerId
						? { ...printer, ...action.payload.data }
						: printer,
				),
			}
		case UPDATE_ROAM_PRINTER_FEATURE_LOADING:
			return {
				...state,
				featuresLoading: {
					...state.featuresLoading,
					[action.payload.printerId]: {
						...state.featuresLoading[action.payload.printerId],
						[action.payload.featureName]: action.payload.loading,
					},
				},
			}
		default:
			return state
	}
}
export { userReducer, printersReducer, paPrintersReducer, roamPrintersReducer }
