import React, { useEffect, useState, useContext } from 'react'
import {
	Button,
	Header,
	IconReload,
	Table,
	Toggle,
	ProgressIndicator,
	useToast,
	IconCheckCircle,
} from '@veneer/core'
import { JarvisPortalTemplate } from '@jarvis/react-portal-additions/dist/index'
import { LastSynced } from './styles'
import { getText } from '../../utils/commonMethods'
import { getPaginationI18n, getTableI18n } from '../../utils/veneerI18n'
import {
	Content,
	DeviceTitleSection,
	DeviceSubTitle,
} from '../RootComponent/styles'
import {
	defaultPageSizeOptions,
	preferences,
	getEnvConfig,
	DEVICE_NAME,
	FRIENDLY_NAME,
	ASCENDING,
	DESCENDING,
	UPDATE_PA_PRINTER_DATA,
	UPDATE_PA_PRINTER_FEATURE_LOADING,
	PREFERRED_RELEASE_INTENT_VALUES,
	PRINT_ON_THE_GO_ENABLED,
	REMOTE_PRINTING,
	PRIVATE_PICKUP,
	PREFERRED_RELEASE_INTENT,
	REQUIRED,
	PA,
} from '../../utils/constants'
import AppContext from '../../context/AppContext'
import { getDateAndTime } from '../../utils/commonMethods'
import { Search } from '@veneer/core'
import PrintersClient from '../../client/PrintersClient'

import './devices.scss'

export const PrintAnywhereContent = ({ localization, ...props }) => {
	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(5)

	const [tableDataFromApi, setTableDataFromApi] = useState([])

	const [deviceData, setDeviceData] = useState([])
	const [dataPerPage, setDataPerPage] = useState([])

	const [searchTerm, setSearchTerm] = useState('')

	const [totalItems, setTotalItems] = useState(0)

	const [orderBy, setOrderBy] = React.useState(DEVICE_NAME)
	const [orderType, setOrderType] = React.useState(ASCENDING)

	const { user, paPrinters, paPrintersDispatch } = useContext(AppContext)

	const { addToast } = useToast()
	const [lastSynced, setLastSynced] = useState(getDateAndTime(localization.language, localization.country))

	const tableColumns = [
		{
			id: 'deviceName',
			label: props.t
				? props.t('columns.printerName', 'PRINTER NAME')
				: getText('columns.printerName'),
		},
		{
			id: 'location',
			label: props.t
				? props.t('columns.location', 'LOCATION')
				: getText('columns.location'),
			sortable: false,
		},
		{
			id: 'printAnywhere',
			label: props.t
				? props.t('columns.printAnywhere', 'PRINT ANYWHERE')
				: getText('columns.printAnywhere'),
			sortable: false,
		},
		{
			id: 'privatePickup',
			label: props.t
				? props.t('columns.privatePickup', 'PRIVATE PICKUP')
				: getText('columns.privatePickup'),
			sortable: false,
		},
	]

	const updatePrinter = async (
		printerId,
		printerData,
		featureData,
		dispatchData,
	) => {
		let feature = featureData.hasOwnProperty(PRINT_ON_THE_GO_ENABLED)
			? REMOTE_PRINTING
			: PRIVATE_PICKUP

		const config = getEnvConfig(props.stack)

		const printersClient = new PrintersClient(
			config.apiBaseUrl,
			props.authProvider,
		)
		try {
			await printersClient.updatePrinter(printerId, printerData)
			paPrintersDispatch({
				type: UPDATE_PA_PRINTER_DATA,
				payload: {
					printerId: printerId,
					data: dispatchData,
				},
			})
		} catch (error) {
			addToast({
				type: 'negative',
				id: 'printAnywhere-printAnywhere-toggle-error-toast',
				text: props.t
					? props.t(
							'remotePrinting.error.updatePrinter.toastText',
							'Update Printer Error',
					  )
					: getText('remotePrinting.error.updatePrinter.toastText'),
				subtitle: props.t
					? props.t(
							'remotePrinting.error.updatePrinter.toastSubTitle',
							'Could not update printer',
					  )
					: getText('remotePrinting.error.updatePrinter.toastSubTitle'),
			})
		} finally {
			paPrintersDispatch({
				type: UPDATE_PA_PRINTER_FEATURE_LOADING,
				payload: {
					printerId: printerId,
					featureName:
						feature === REMOTE_PRINTING
							? PRINT_ON_THE_GO_ENABLED
							: PREFERRED_RELEASE_INTENT,
					loading: false,
				},
			})
		}
	}

	const getPrinterNameData = (printer) => {
		return (
			<div>
				<div className='printer_friendlyname_text'>
					{printer.friendlyName ? printer.friendlyName : printer.modelName}
				</div>
				<div className='printer_modelname_text'>{printer.modelName}</div>
			</div>
		)
	}

	const handlePrintOnTheGoChange = (printer, printOnTheGoEnabled) => {
		let printerId = printer.id
		paPrintersDispatch({
			type: UPDATE_PA_PRINTER_FEATURE_LOADING,
			payload: {
				printerId: printerId,
				featureName: PRINT_ON_THE_GO_ENABLED,
				loading: true,
			},
		})

		let updatedPrinterSolutions = printer.printerSolutions.map((ps) => {
			return ps.name === PA ? { ...ps, enabled: printOnTheGoEnabled } : ps
		})

		updatePrinter(
			printerId,
			{
				printerSolutions: [{ name: PA, enabled: printOnTheGoEnabled }],
			},
			{ printOnTheGoEnabled },
			{ printerSolutions: updatedPrinterSolutions },
		)
	}

	const handlePreferredReleaseIntentChange = (
		printer,
		preferredReleaseIntent,
	) => {
		let printerId = printer.id
		paPrintersDispatch({
			type: UPDATE_PA_PRINTER_FEATURE_LOADING,
			payload: {
				printerId: printerId,
				featureName: PREFERRED_RELEASE_INTENT,
				loading: true,
			},
		})

		let updatedPrinterSolutions = printer.printerSolutions.map((ps) => {
			return ps.name === PA
				? { ...ps, preferredReleaseIntent: preferredReleaseIntent }
				: ps
		})

		updatePrinter(
			printerId,
			{
				printerSolutions: [
					{ name: PA, preferredReleaseIntent: preferredReleaseIntent },
				],
			},

			{ preferredReleaseIntent },
			{ printerSolutions: updatedPrinterSolutions },
		)
	}
	const renderPrintAnywhere = (printer) => {
		let paEnabled = printer.printerSolutions.filter((ps) => ps.name === PA)[0]
			.enabled
		if (user.nonOwner) {
			return paEnabled ? (
				renderCheckMark(
					props.t ? props.t('toggle.On', 'On') : getText('toggle.On'),
				)
			) : (
				<span
					data-testid='potg-printer-private-pickup-text'
					className='private_pickup_text'
				>
					{props.t ? props.t('toggle.Off', 'Off') : getText('toggle.Off')}
				</span>
			)
		} else {
			return (
				<div>
					<Toggle
						id={`toggle_printOnTheGo_printer_${printer.id}`}
						className='printer__toggle'
						disabled={
							paPrinters.featuresLoading[printer.id].printOnTheGoEnabled
						}
						on={paEnabled}
						onChange={(isOn) => handlePrintOnTheGoChange(printer, isOn)}
						label={paEnabled
							? props.t
								? props.t('toggle.On', 'On')
								: getText('toggle.On')
							: props.t
							? props.t('toggle.Off', 'Off')
							: getText('toggle.Off')}
					/>
				</div>
			)
		}
	}

	const renderPrivatePickup = (printer) => {
		let printOnTheGoEnabled = printer.printerSolutions.filter(
			(ps) => ps.name === PA,
		)[0].enabled

		let preferredReleaseIntent = printer.printerSolutions.filter(
			(ps) => ps.name === PA,
		)[0].preferredReleaseIntent

		if (user.nonOwner || !printer.supportsPull) {
			if (printOnTheGoEnabled && printer.supportsPull) {
				//NONOWNER && (PRINTONTHEGOENABLED && SUPPORTSPULL)
				if (
					preferredReleaseIntent === PREFERRED_RELEASE_INTENT_VALUES.HOLD_ONLY
				) {
					return renderCheckMark(REQUIRED)
				} else {
					return (
						<span data-testid='potg-printer-private-pickup-non-owner-disabled-toggle'>
							{/* {'Disabled'} */}
							<Toggle id='disabledToggle' defaultOn disabled />
						</span>
					)
				}
			} else {
				//  (!PRINTONTHEGOENABLED || !SUPPORTSPULL)
				//   the only time we have the owner==true here is when supportsPull is false
				return (
					<span
						data-testid='potg-printer-private-pickup-non-owner-text'
						className='private_pickup_text'
					>
						{props.t
							? props.t('toggle.NotAvailable', 'Not Available')
							: getText('toggle.NotAvailable')}
					</span>
				)
			}
		} else {
			//OWNER && SUPPORTSPULL
			return (
				<div style={{ width: 'max-content'}}>
					{' '}
					<Toggle
						id={`toggle_privatePickUp_printer_${printer.id}`}
						className='printer__toggle'
						disabled={
							!printOnTheGoEnabled
							// ||
							// userPrinters.featuresLoading[printer.id].preferredReleaseIntent
						}
						on={
							preferredReleaseIntent ===
							PREFERRED_RELEASE_INTENT_VALUES.HOLD_ONLY
						}
						onChange={(isOn) => {
							if (isOn) {
								handlePreferredReleaseIntentChange(
									printer,
									PREFERRED_RELEASE_INTENT_VALUES.HOLD_ONLY,
								)
							} else {
								if (props.clientReleaseIntent) {
									handlePreferredReleaseIntentChange(
										printer,
										PREFERRED_RELEASE_INTENT_VALUES.DEFAULT_AUTO,
									)
								} else {
									handlePreferredReleaseIntentChange(
										printer,
										PREFERRED_RELEASE_INTENT_VALUES.AUTO_ONLY,
									)
								}
							}
						}}
						label={preferredReleaseIntent ===
							PREFERRED_RELEASE_INTENT_VALUES.HOLD_ONLY
								? props.t
									? props.t('toggle.On', 'On')
									: getText('toggle.On')
								: props.t
								? props.t('toggle.Off', 'Off')
								: getText('toggle.Off')}
					/>
				</div>
			)
		}
	}

	const renderCheckMark = (text) => {
		return (
			<div
				data-testid='potg-printer-checkmark-text'
				className='icon_checkmark_text'
			>
				{text}
				<span className='icon_checkmark'>
					<IconCheckCircle size={24} color='green6' />
				</span>
			</div>
		)
	}

	const createPrinterDataObject = (printer) => {
		return {
			printerId: printer.id,
			modelName: printer.modelName,
			deviceName: getPrinterNameData(printer),
			location: <div>{printer.location ? printer.location : '-'}</div>,
			printAnywhere: paPrinters.featuresLoading[printer.id]
				.printOnTheGoEnabled ? (
				<ProgressIndicator appearance='circular' />
			) : (
				renderPrintAnywhere(printer)
			),
			privatePickup: paPrinters.featuresLoading[printer.id]
				.preferredReleaseIntent ? (
				<ProgressIndicator appearance='circular' />
			) : (
				renderPrivatePickup(printer)
			),
			friendlyName: printer.friendlyName
				? printer.friendlyName
				: printer.modelName,
		}
	}

	const formTableData = () => {
		if (paPrinters.printers) {
			let tableData = paPrinters.printers.map((printer) => {
				return createPrinterDataObject(printer)
			})

			let sortedData = sort(tableData, DEVICE_NAME, ASCENDING)
			setTableDataFromApi(sortedData)
			handleData(sortedData)
		}

		//leaving the following commented out code here on purpose for now to use mocked data for bug fixing
		// let sortedMockedData = sort(mockedData, DEVICE_NAME, ASCENDING)
		// setTableDataFromApi(sortedMockedData)
		// handleData(sortedMockedData)
	}

	const handleData = (data) => {
		setTotalItems(data.length)
		setDeviceData(data.length > 0 ? data : [])
		if (data.length > pageSize) {
			let startIndex = currentPage * pageSize - pageSize
			let endIndex =
				currentPage * pageSize > data.length
					? data.length
					: currentPage * pageSize
			setDataPerPage(data.slice(startIndex, endIndex))
		} else {
			setDataPerPage(data)
		}
	}

	useEffect(() => {
		formTableData()
	}, [paPrinters])

	const changeSearchText = (searchParam) => {
		setSearchTerm(searchParam)
		setCurrentPage(1)
		if (searchParam.length > 0) {
			let filteredData = []
			let trimmedSearchFilter = searchParam.trim()
			filteredData = tableDataFromApi.filter((dataObj) => {
				return (
					(dataObj.friendlyName &&
						dataObj.friendlyName
							.toLowerCase()
							.includes(trimmedSearchFilter.toLowerCase())) ||
					(dataObj.modelName &&
						dataObj.modelName
							.toLowerCase()
							.includes(trimmedSearchFilter.toLowerCase()))
				)
			})
			let sortedFilteredData = sort(filteredData, DEVICE_NAME, ASCENDING)
			handleData(sortedFilteredData)
		} else {
			handleData(tableDataFromApi)
		}
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
		let startIndex = page * pageSize - pageSize
		let endIndex =
			page * pageSize > deviceData.length ? deviceData.length : page * pageSize
		setDataPerPage(deviceData.slice(startIndex, endIndex))
	}
	const handlePageSizeChange = (event, option) => {
		setCurrentPage(1)
		setPageSize(option.value)
		let startIndex = 0
		let endIndex =
			option.value > deviceData.length ? deviceData.length : option.value

		if (deviceData[startIndex] && deviceData[endIndex - 1]) {
			setDataPerPage(deviceData.slice(startIndex, endIndex))
		}
	}
	const handleReloadPage = () => {
		setSearchTerm('')
		props.fetchReleaseIntent()
		props.fetchPrinters()
		//TODO: NEED TO CALL props.splitPrintAnywhereAndRoamPrinters
		formTableData()
		setLastSynced(getDateAndTime(localization?.language, localization?.country))
	}

	const descendingComparator = (a, b, orderBy) => {
		const aSort = a[orderBy] && a[orderBy].toString().toUpperCase()
		const bSort = b[orderBy] && b[orderBy].toString().toUpperCase()

		if (bSort < aSort) {
			return -1
		}
		if (bSort > aSort) {
			return 1
		}
		return 0
	}

	const sort = (array, orderBy, orderType) => {
		return array.sort((a, b) => {
			const value =
				orderType === DESCENDING
					? descendingComparator(a, b, FRIENDLY_NAME)
					: -descendingComparator(a, b, FRIENDLY_NAME)

			return value
		})
	}

	const handleSort = (_, { id, type }) => {
		setOrderBy(id)
		setOrderType(type)
	}

	const sortedDataPerPage = sort(dataPerPage, orderBy, orderType)

	return (
		// <div id='device-list-section' data-testid='device-list-section'>
		<Content>
			<JarvisPortalTemplate
				title={null}
				description={null}
				direction='ltr'
				fullWidth
				data-testid='ecp-portal-template'
				mainContent={
					<>
						{user.roamQueueEnabled && (
							<DeviceTitleSection data-testid='device-title-section'>
								<DeviceSubTitle data-testid='device-subtitle'>
									{user.RoamQueueEnabled
										? props.t
											? props.t(
													'remotePrinting.noTabSubTitle',
													'Print to any web-connected printer any time, from any location by enabling Print Anywhere. Turn on Private Pickup to ensure that a print job gets released only when the sender is near the selected printer.',
											  )
											: getText('remotePrinting.noTabSubTitle')
										: props.t
										? props.t(
												'remotePrinting.noTabSubTitle',
												'Print to any web-connected printer any time, from any location by enabling Print Anywhere. Turn on Private Pickup to ensure that a print job gets released only when the sender is near the selected printer.',
										  )
										: getText('remotePrinting.noTabSubTitle')}
								</DeviceSubTitle>
							</DeviceTitleSection>
						)}
					</>
				}
			/>
			<Button
				aria-label='Tertiary'
				data-testid='reload-button'
				onClick={() => handleReloadPage()}
				leadingIcon={
					<IconReload className='IconRelodColor' color='hpBlue6' />
				}
				appearance='tertiary'
			/>
			<LastSynced testid='last-synced-date'>
				{' '}
				{props.t
					? props.t('remotePrinting.lastSynced', 'Last Updated')
					: getText('remotePrinting.lastSynced')}
				: {lastSynced}
			</LastSynced>
			<Header
				position='relative'
				data-testid='device-table-header'
				customStyle={{
					paddingLeft: '0px',
					paddingRight: '0px',
					border: 'none',
					marginBottom: '15px',
					background: 'transparent',
				}}
				leadingArea={
					<div id='search-box-section' style={{ width: '310px' }}>
						<Search
							value={searchTerm}
							id='controlled-search'
							data-testid='search-box'
							label={props.t
								? props.t('remotePrinting.search', 'Search device name')
								: getText('remotePrinting.search')}
							onChange={(value) => changeSearchText(value)} 
							onPointerEnterCapture={() => {}}
							onPointerLeaveCapture={() =>  {}}						/>
					</div>
				}
			/>

			{props.errorToFetchPrinters ||
			props.errorToFetchReleaseIntent ||
			props.errorToFetchUserAndRoles ? (
				<div>
					{props.t
						? props.t(
								'remotePrinting.error.fetchData',
								'Something went wrong fetching required data.',
						  )
						: getText('remotePrinting.error.fetchData')}
				</div>
			) : (
				<Table
					data-testid='devices-data-table'
					i18n={getTableI18n({
						noItems: props.t
							? props.t('veneer.table.noItems')
							: 'No Items Found',
					})}
					component={'table'}
					columns={tableColumns}
					data={sortedDataPerPage || []}
					onSort={handleSort}
					preferences={preferences}
					//TODO: NEED TO UNCOMMENT THE FOLLOWING AND USE IT INTEAD OF false
					loading={
						props.fetchingUserAndRoles ||
						props.fetchingReleaseIntent ||
						props.fetchingPrinters
					}
					loadingDataLength={3}
					pagination={{
						currentPage,
						onPageChange: handlePageChange,
						onPageSizeChange: handlePageSizeChange,
						pageSize,
						pageSizeOptions: defaultPageSizeOptions,
						popoverPlacement: 'top-start',
						totalItems,
						i18n: getPaginationI18n({
							pageSizeInformation: props.t
								? props.t('veneer.pagination.pageSizeInformation')
								: 'of %{total}',
						}),
					}}
				/>
			)}
		</Content>
	)
}
