import React, { useEffect, useState } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import { ShellProps } from '../src/types/shell'
import packageInfo from '../package.json'
import { RootComponent } from '../src/'
import { TranslatorFunctionType } from '../src/types/localization'
import resources from '../src/assets/locale'

/**
 *
 * @param props
 */

declare global {
	interface Window {
		Shell: ShellProps
	}
}
export default function Root(props) {
	const { v1 } = window.Shell as ShellProps
	const { stack } = props
	const [t, setT] = useState<TranslatorFunctionType>()
	const interfaces = window.Shell.v1
	const namespace = '@jarvis/react-smb-print-anywhere'
	// Create a translator function by providing the resource files
	useEffect(() => {
		interfaces.localization
			.createTranslatorFunction(resources)
			.then((v) => setT(() => v))
	}, [interfaces])

	//Wait for the translator function creation to show the content
	if (!t) {
		return null
	}
	return (
		<section id={namespace}>
			<RootComponent
				id={packageInfo.name}
				{...{ ...props, ...interfaces, t }}
				authProvider={v1.authProvider}
				search={props.search || true}
				export={props.export || true}
				allDevices={props.allDevices || true}
				stack={stack || Stack.dev}
			/>
		</section>
	)
}
