import React from 'react'
import { OptionInterface } from '@veneer/core'
import { Toggle } from '@veneer/core'
import { TablePreferences } from '@veneer/core'

export const PrintersFetchError = 'Error fetching printers.'
export const printerUpdateError = 'Error updating the printer.'
export const clientReleaseIntentError = 'Error fetching client release intent.'
export const userFetchError = 'Error fetching user.'
export const userRoleFetchError = 'Error fetching user roles.'
export const userAccountFetchError = 'Error fetching user account.'

export const defaultPageSizeOptions: OptionInterface<number>[] = [
	{ value: 5 },
	{ value: 10 },
	{ value: 15 },
	{ value: 20 },
]

export const preferences: TablePreferences = {
	sortBy: {
		id: 'deviceName',
		type: 'ascending',
	},
	width: [
		{ columnId: 'deviceName', width: 100 },
		{ columnId: 'location', width: 100 },
		{ columnId: 'printAnywhere', width: 100 },
		{ columnId: 'privatePickup', width: 100 },
	],
}

export const roamTablePreferences: TablePreferences = {
	sortBy: {
		id: 'deviceName',
		type: 'ascending',
	},
	width: [
		{ columnId: 'deviceName', width: 100 },
		{ columnId: 'location', width: 100 },
		{ columnId: 'roamQueuePrinting', width: 100 },
	],
}

export const mockedData = [
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>LLL bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>Blast-pro-6344</p>
				</div>
			</div>
		),
		location: 'Building 6 Hall',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'LLL bla',
		modelName: 'Blast-pro-6344',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>BBB bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>TR-978 Print Pro</p>
				</div>
			</div>
		),
		location: 'Building 1 Lobby',
		remotePrinting: <Toggle defaultOn id={''} />,
		privatePickup: <Toggle id={''} />,
		friendlyName: 'BBB bla',
		modelName: 'TR-978 Print Pro',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>AAA Bla AAA Bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>JetPrint-67678</p>
				</div>
			</div>
		),
		location: 'Building 5 Lobby',
		remotePrinting: <Toggle defaultOn id={''} />,
		privatePickup: 'Not Available',
		friendlyName: 'AAA Bla AAA Bla',
		modelName: 'JetPrint-67678',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>CCC bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>MF-Colored-576</p>
				</div>
			</div>
		),

		location: 'Building 2 Lobby',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'CCC bla',
		modelName: 'MF-Colored-576',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>HHH bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>HP-pro-3333</p>
				</div>
			</div>
		),
		location: 'Building 1 Lobby',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'HHH bla',
		modelName: 'HP-pro-3333',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>EEE bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>HP pro-jet MD</p>
				</div>
			</div>
		),
		location: 'Kevin Office',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'EEE bla',
		modelName: 'HP pro-jet MD',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>FFF bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>Auto-jet 542</p>
				</div>
			</div>
		),
		location: 'Building 6 Hall',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'FFF bla',
		modelName: 'Auto-jet 542',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>DDD bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>HP Deskjet D-756</p>
				</div>
			</div>
		),
		location: 'Building 3 Lobby',
		remotePrinting: <Toggle defaultOn id={''} />,
		privatePickup: <Toggle id={''} />,
		friendlyName: 'DDD bla',
		modelName: 'HP Deskjet D-756',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>GGG bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>HP-pro-7623</p>
				</div>
			</div>
		),
		location: 'Building 5 Lobby',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'GGG bla',
		modelName: 'HP-pro-7623',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>KKK bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>SmitherPro-4344</p>
				</div>
			</div>
		),
		location: 'Kevin Office',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'KKK bla',
		modelName: 'SmitherPro-4344',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>III bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>HPSmart-pro-980</p>
				</div>
			</div>
		),
		location: 'Building 2 Lobby',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'III bla',
		modelName: 'HPSmart-pro-980',
	},
	{
		deviceName: (
			<div>
				<div>
					<p style={{ fontSize: 16 }}>JJJ bla</p>
				</div>
				<div>
					<p style={{ fontSize: 12 }}>JetSmart-pro-880</p>
				</div>
			</div>
		),
		location: 'Building 3 Lobby',
		remotePrinting: 'Off',
		privatePickup: 'Not Available',
		friendlyName: 'JJJ bla',
		modelName: 'JetSmart-pro-880',
	},

	// {
	// 	deviceName: 'MMM Aaron WFH Office',
	// 	location: 'Building 5 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'NNN Building One Front desk',
	// 	location: 'Building 1 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'OOO Janet Office',
	// 	location: 'Building 2 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'PPP HP Officejet Pro 9015e',
	// 	location: 'Building 3 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'QQQ Kevin Office',
	// 	location: 'Kevin Office',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'RRR HP Officejet Pro 2345e',
	// 	location: 'Building 6 Hall',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'SSS Aaron WFH Office',
	// 	location: 'Building 5 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'TTT Building One Front desk',
	// 	location: 'Building 1 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'UUU Janet Office',
	// 	location: 'Building 2 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'VVV HP Officejet Pro 9015e',
	// 	location: 'Building 3 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'WWW Kevin Office',
	// 	location: 'Kevin Office',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'XXX HP Officejet Pro 2345e',
	// 	location: 'Building 6 Hall',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'YYY Aaron WFH Office',
	// 	location: 'Building 5 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'ZZZ Building One Front desk',
	// 	location: 'Building 1 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AB Janet Office',
	// 	location: 'Building 2 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AC HP Officejet Pro 9015e',
	// 	location: 'Building 3 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AD Kevin Office',
	// 	location: 'Kevin Office',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AF HP Officejet Pro 2345e',
	// 	location: 'Building 6 Hall',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AG Aaron WFH Office',
	// 	location: 'Building 5 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AH Building One Front desk',
	// 	location: 'Building 1 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AI Janet Office',
	// 	location: 'Building 2 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AJ HP Officejet Pro 9015e',
	// 	location: 'Building 3 Lobby',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AK Kevin Office',
	// 	location: 'Kevin Office',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
	// {
	// 	deviceName: 'AL HP Officejet Pro 2345e',
	// 	location: 'Building 6 Hall',
	// 	remotePrinting: 'Off',
	// 	privatePickup: 'Not Available',
	// },
]

const environmentVariables = {
	dev: {
		apiBaseUrl: 'https://api-rsantos2.goblin.hpalpine.com',
	},
	pie: {
		apiBaseUrl: 'https://api-rsantos2.goblin.hpalpine.com',
	},
	stage: {
		apiBaseUrl: 'https://api-staging.ogre.hpalpine.com',
	},
	prod: {
		apiBaseUrl: 'https://roam.api.hp.com',
	},
}

export function getEnvConfig(env) {
	switch (env) {
		case 0:
		case 'dev':
		case 'Dev':
		case 'DEV':
			return environmentVariables.dev
		case 1:
		case 'pie':
		case 'Pie':
		case 'PIE':
			return environmentVariables.pie
		case 2:
		case 'stage':
		case 'Stage':
		case 'STAGE':
			return environmentVariables.stage
		case 3:
		case 'prod':
		case 'Prod':
		case 'PROD':

		case 'production':
			return environmentVariables.prod

		default:
			return environmentVariables.pie
	}
}

export const DEVICE_NAME = 'deviceName'
export const FRIENDLY_NAME = 'friendlyName'
export const ASCENDING = 'ascending'
export const DESCENDING = 'descending'

//Action types
export const FETCH_PRINTERS = 'FETCH_PRINTERS'
export const FETCH_PRINTERS_SUCCESS = 'FETCH_PRINTERS_SUCCESS'
export const SET_PA_PRINTERS = 'SET_PA_PRINTERS'
export const SET_ROAM_PRINTERS = 'SET_ROAM_PRINTERS'
export const UPDATE_PA_PRINTER_DATA = 'UPDATE_PA_PRINTER_DATA'
export const UPDATE_PA_PRINTER_FEATURE_LOADING =
	'UPDATE_PA_PRINTER_FEATURE_LOADING'
export const UPDATE_ROAM_PRINTER_DATA = 'UPDATE_PA_PRINTER_DATA'
export const UPDATE_ROAM_PRINTER_FEATURE_LOADING =
	'UPDATE_PA_PRINTER_FEATURE_LOADING'
export const SET_NON_OWNER = 'SET_NON_OWNER'
export const SET_ROAM_QUEUE_ENABLED = 'SET_ROAM_QUEUE_ENABLED'

export const PREFERRED_RELEASE_INTENT_VALUES = {
	DEFAULT_AUTO: 'DEFAULT_AUTO',
	DEFAULT_HOLD: 'DEFAULT_HOLD',
	AUTO_ONLY: 'AUTO_ONLY',
	HOLD_ONLY: 'HOLD_ONLY',
}
export const REQUIRED = 'Required'
export const PRINT_ON_THE_GO_ENABLED = 'printOnTheGoEnabled'
export const ROAM_QUEUE_PRINTING = 'roamQueuePrinting'
export const REMOTE_PRINTING = 'remotePrinting'
export const PRIVATE_PICKUP = 'privatePickup'
export const PREFERRED_RELEASE_INTENT = 'preferredReleaseIntent'
export const PA = 'PA'
export const ROAM = 'ROAM'
export const PRINTER_SOLUTIONS_ERROR_TEXT = 'Printer API Error'
export const PRINTER_SOLUTIONS_ERROR_SUBTITLE =
	'Printer API should return the printerSolution property of each printer'
