import { useState, useEffect, useContext } from 'react'
import {
	getEnvConfig,
	FETCH_PRINTERS,
	FETCH_PRINTERS_SUCCESS,
} from '../utils/constants'
import PrintersClient from '../client/PrintersClient'
import AppContext from '../context/AppContext'

export const useFetchPrinters = ({ stack, authProvider }) => {
	const { userPrintersDispatch } = useContext(AppContext)
	const [printers, setPrinters] = useState([])
	const [fetchingPrinters, setFetchingPrinters] = useState(true)
	const [errorToFetchPrinters, setErrorToFetchPrinters] = useState(false)

	const config = getEnvConfig(stack)

	const fetchPrinters = async () => {
		setErrorToFetchPrinters(false)
		setFetchingPrinters(true)

		//TODO: DO WE NEED THIS DISPATCH AT ALL?
		userPrintersDispatch({
			type: FETCH_PRINTERS,
		})
		const printersClient = new PrintersClient(config.apiBaseUrl, authProvider)
		try {
			let printersResult = await printersClient.getPrinters()

			setPrinters(printersResult.content)
			userPrintersDispatch({
				type: FETCH_PRINTERS_SUCCESS,
				payload: {
					printers: printersResult.content,
				},
			})
		} catch (error) {
			setErrorToFetchPrinters(true)
		} finally {
			setFetchingPrinters(false)
		}
	}

	useEffect(() => {
		fetchPrinters()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		fetchPrinters,
		printers,
		errorToFetchPrinters,
		fetchingPrinters,
	}
}
