import { JarvisWebHttpClient } from '@jarvis/web-http'

import { clientReleaseIntentError } from '../utils/constants'

class ReleaseIntentClient {
	baseUrl
	jarvisWebClient

	constructor(baseUrl, jarvisAuthProvider) {
		this.baseUrl = baseUrl

		this.jarvisWebClient = new JarvisWebHttpClient({
			host: this.baseUrl,
			defaultAuthProvider: jarvisAuthProvider,
		})
	}

	async getClientReleaseIntent() {
		const response = await this.jarvisWebClient.get({
			url: this.clientReleaseIntentEndpoint(),
		})
		if (this.isStatusCodeOk(response.status)) {
			if (response.data) {
				return response.data
			}
			return []
		} else {
			throw new Error(clientReleaseIntentError)
		}
		throw response.statusText
	}

	clientReleaseIntentEndpoint() {
		return `${this.baseUrl}/api/v1/applicationProperty`
	}

	isStatusCodeOk(status) {
		return status > 199 && status < 400
	}
}

export default ReleaseIntentClient
