import React from "react";

import { Header, Title, TitleCounter, Card, CardBody } from "./styles";

export { Section, BodyText, Link } from "./styles";

interface CardContentProps {
  title: string;
  titleCounter?: string;
  headerRightArea?: React.ReactNode;
  children?: React.ReactNode;
}

interface WidgetCardProps {
  title: string;
  titleCounter?: string;
  headerRightArea?: React.ReactNode;
  children?: React.ReactNode;
  [key: string]: any;
}

/**
 * React component with the content that renders inside the WidgetCard.
 * @component
 * @param {string} title The title to display above the component
 * @param {React.ReactNode} headerRightArea The react element to render on the side of the card header
 * @param {React.ReactNode} children The main react element to render on the page
 */
const CardContent = ({
  title,
  titleCounter,
  headerRightArea,
  children,
}: CardContentProps) => (
  <>
    <Header>
      <Title>
        {title}
        {titleCounter ? <TitleCounter>({titleCounter})</TitleCounter> : ""}
      </Title>

      {headerRightArea ? headerRightArea : null}
    </Header>
    <CardBody>{children}</CardBody>
  </>
);

/**
 * React component that renders a card with a header to be used as a wrapper for Portal Shell widgets.
 * @component
 * @param {string} title The title to display above the component
 * @param {React.ReactNode} headerRightArea The react element to render on the side of the card header
 * @param {React.ReactNode} children The main react element to render on the page
 */
export const WidgetCard = ({
  title,
  titleCounter,
  headerRightArea,
  children,
  ...props
}: WidgetCardProps) => (
  <Card
    {...props}
    appearance="dropShadow"
    content={
      <CardContent
        title={title}
        titleCounter={titleCounter}
        headerRightArea={headerRightArea}
      >
        {children}
      </CardContent>
    }
  />
);
