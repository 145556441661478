import React, { ReactElement } from 'react'
import {
    Container,
    GridSeparator,
    Description,
    Grid,
    MainContent,
    Title,
    TitleContent,
} from './styles';

export type TabsId = number | string;
/**
 * JarvisPortalPage Properties
 */
type JarvisPortalPageProps = {
  /** The title to display above the component */
  title?: string

  /** The Subtitle to display above the component */
  description?: string

  /** The main react element to render on the page*/
  mainContent: ReactElement

  /** Direction to display content */
  direction: string

  /** If false, content is a grid (cols = 640px, 300px) if window is bigger than 1280px */
  fullWidth?: boolean
}

/**
* React component that renders a UCDE Style Page.
* @component
* @param {JarvisPortalPageProps} props The react props
*/
export const JarvisPortalTemplate = (props: JarvisPortalPageProps) => {
    const {direction, title, description, mainContent, fullWidth} = props;
    
    const renderGridSeparator = title || description;
    const renderTitleContent = title || description;

    const ContentWrapper = React.useMemo(() => {
        return fullWidth ? 'div' : Grid
    }, [fullWidth]);

    return (
        <Container dir={direction}>
            {renderGridSeparator && <GridSeparator>
                {renderTitleContent && <TitleContent>
                    {title && <Title>{title}</Title>}
                    {description && <Description>{description}</Description>}
                </TitleContent>}
            </GridSeparator>}
            <ContentWrapper>
                <MainContent>
                    {mainContent}
                </MainContent>
            </ContentWrapper>
        </Container>
    )
}
