import { JarvisWebHttpClient } from '@jarvis/web-http'

import { PrintersFetchError, printerUpdateError } from '../utils/constants'

class PrintersClient {
	baseUrl
	jarvisWebClient

	constructor(baseUrl, jarvisAuthProvider) {
		this.baseUrl = baseUrl

		this.jarvisWebClient = new JarvisWebHttpClient({
			host: this.baseUrl,
			defaultAuthProvider: jarvisAuthProvider,
		})
	}

	async getPrinters() {
		const response = await this.jarvisWebClient.get({
			url: this.fetchPrintersEndpoint(),
		})
		if (this.isStatusCodeOk(response.status)) {
			if (response.data) {
				return response.data
			}
			return []
		} else {
			throw new Error(PrintersFetchError)
		}
		throw response.statusText
	}

	async updatePrinter(printerId, printerData) {
		const response = await this.jarvisWebClient.patch({
			url: this.updatePrinterEndpoint(printerId),
			data: printerData,
		})
		if (this.isStatusCodeOk(response.status)) {
			if (response.data) {
				return response.data
			}
			return []
		} else {
			throw new Error(printerUpdateError)
		}
		throw response.statusText
	}

	fetchPrintersEndpoint() {
		return `${this.baseUrl}/api/v2/printer`
	}
	updatePrinterEndpoint(printerId) {
		return `${this.baseUrl}/api/v2/printer/${printerId}`
	}

	isStatusCodeOk(status) {
		return status > 199 && status < 400
	}
}

export default PrintersClient
