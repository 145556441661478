import styled from "styled-components";
import tokens from "@veneer/tokens"
import Footer from "@veneer/core/dist/scripts/footer/index";

export const PortalFooter = styled(Footer)`
  &,
  * {
    border: none !important;
    background: none !important;
  }
  > div {
    height: 64px;
  }
`;

export const LeftArea = styled.div`
  font-family: ${tokens.fontFamilyLight};
  color: ${tokens.colorGray5};
  font-size: ${tokens.fontSize1};
  line-height: ${tokens.lineHeight2};
  max-width: 300px;
  overflow-x: hidden;
`;

export const RightArea = styled.div`
  font-family: ${tokens.fontFamilyLight};
  color: ${tokens.colorHpBlue6};
  font-size: ${tokens.fontSize2};
  line-height: ${tokens.lineHeight3};
  width: 127px;
`;

export const FooterItem = styled.div`
  font-family: ${tokens.fontFamilyLight};
  color: ${tokens.colorGray5};
  font-size: ${tokens.fontSize2};
  line-height: ${tokens.lineHeight3};
  display: inline-block;
  margin: 0px ${tokens.lineHeight3};
`;

export const RightFooterItemsContent = styled.div`
  align-items: "center";
  display: "flex";
`;
