import {
  prop,
  path,
} from 'ramda';

export const stylesIf = (propName, trueValue = '', falseValue = '') => props => (
  prop(propName, props)
    ? trueValue
    : falseValue
);

export const breakpoints = {
  mobile: '414px',
  tablet: '640px',
  wide: '1280px',
  immense: '1920px',
};

export const theme = {
  fonts: {
    familyLight: '"hp-simplified-light", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    family: '"hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    sm: '12px',
    md: '16px',
    lg: '20px',
    xlg: '24px',
    xxlg: '32px',
    xxxlg: '48px',
    godzilla: '60px',
  },
  colors: {
    hpBlue: '#0096d5',
    black: '#000000',
    dark: '#212121',
    nearDark: '#313131',
    darkGrey: '#404040',
    white: '#FFFFFF',
    lightBackgroundGrey: '#F4F4F4',
  },
  breakpoints,
};

export const paths = {
  colorsDarkGrey: path(['theme', 'colors', 'darkGrey']),
  colorsWhite: path(['theme', 'colors', 'white']),
  colorsNearDark: path(['theme', 'colors', 'nearDark']),
  colorsDark: path(['theme', 'colors', 'dark']),
  colorsBlack: path(['theme', 'colors', 'black']),
  colorsHpBlue: path(['theme', 'colors', 'hpBlue']),
  colorsLightBackgroundGrey: path(['theme', 'colors', 'lightBackgroundGrey']),
  fontsSm: path(['theme', 'fonts', 'sm']),
  fontsMd: path(['theme', 'fonts', 'md']),
  fontsLg: path(['theme', 'fonts', 'lg']),
  fontsXLg: path(['theme', 'fonts', 'xlg']),
  fontsXXLg: path(['theme', 'fonts', 'xxlg']),
  fontsXXXLg: path(['theme', 'fonts', 'xxxlg']),
  fontsGodzilla: path(['theme', 'fonts', 'godzilla']),
  fontsFamily: path(['theme', 'fonts', 'family']),
  fontsFamilyLight: path(['theme', 'fonts', 'familyLight']),
  breakMobile: path(['theme', 'breakpoints', 'mobile']),
  breakTablet: path(['theme', 'breakpoints', 'tablet']),
  breakWide: path(['theme', 'breakpoints', 'wide']),
  breakImmense: path(['theme', 'breakpoints', 'immense']),
};

export default {
  stylesIf,
};
