import React, { useState, useEffect, useContext } from 'react'
import { JarvisPortalTemplate } from '@jarvis/react-portal-additions/dist/index'
import { PrintAnywhereContent } from '../PrintAnywhereContent'
import { useFetchPrinters } from '../../hooks/useFetchPrinters'
import { useReleaseIntent } from '../../hooks/useReleaseIntent'
import { useUser } from '../../hooks/useUser'
import AppContext from '../../context/AppContext'
//import { RoamContent } from '../RoamContent'
import { Tabs, useToast } from '@veneer/core'

import {
	Content,
	DeviceTitleSection,
	DeviceTitle,
	DeviceSubTitle,
} from '../RootComponent/styles'

import '../PrintAnywhereContent/devices.scss'

import { getText } from '../../utils/commonMethods'
import {
	SET_PA_PRINTERS,
	SET_ROAM_PRINTERS,
	PA,
	ROAM,
	PRINTER_SOLUTIONS_ERROR_TEXT,
	PRINTER_SOLUTIONS_ERROR_SUBTITLE,
} from '../../utils/constants'

export const RemotePrinting = (props) => {
	const [selectedTabId, setSelectedTabId] = useState(0)

	const { user, userPrinters, paPrintersDispatch, roamPrintersDispatch } =
		useContext(AppContext)

	const { errorToFetchUserAndRoles, fetchingUserAndRoles } = useUser({
		stack: props.stack,
		authProvider: props.authProvider,
	})
	const { addToast } = useToast()
	const {
		fetchReleaseIntent,
		clientReleaseIntent,
		errorToFetchReleaseIntent,
		fetchingReleaseIntent,
	} = useReleaseIntent({ stack: props.stack, authProvider: props.authProvider })

	const { fetchPrinters, errorToFetchPrinters, fetchingPrinters } =
		useFetchPrinters({
			stack: props.stack,
			authProvider: props.authProvider,
		})

	const printAnywhereContent = (
		<PrintAnywhereContent
			{...{
				...props,
				errorToFetchUserAndRoles,
				fetchingUserAndRoles,
				fetchReleaseIntent,
				clientReleaseIntent,
				errorToFetchReleaseIntent,
				fetchingReleaseIntent,
				fetchPrinters,
				errorToFetchPrinters,
				fetchingPrinters,
			}}
		/>
	)
	// const roamContent = (
	// 	<RoamContent
	// 		{...{
	// 			...props,
	// 			errorToFetchUserAndRoles,
	// 			fetchingUserAndRoles,
	// 			fetchReleaseIntent,
	// 			clientReleaseIntent,
	// 			errorToFetchReleaseIntent,
	// 			fetchingReleaseIntent,
	// 			fetchPrinters,
	// 			errorToFetchPrinters,
	// 			fetchingPrinters,
	// 		}}
	// 	/>
	// )

	const onChangeTab = (tabId) => {
		if (!user.roamQueueEnabled) {
			return
		}
		setSelectedTabId(tabId)
	}

	const splitPrintAnywhereAndRoamPrinters = async () => {
		let paPrintersArray = []
		let roamPrintersArray = []
		userPrinters.printers.forEach((printer) => {
			if (!printer.printerSolutions) {
				addToast({
					type: 'negative',
					id: 'printAnywhere-printAnywhere-toggle-error-toast',
					text: PRINTER_SOLUTIONS_ERROR_TEXT,
					subtitle: PRINTER_SOLUTIONS_ERROR_SUBTITLE,
				})
			} else {
				printer.printerSolutions.forEach((ps) => {
					if (ps.name === PA) {
						paPrintersArray.push(printer)
					}
					if (ps.name === ROAM) {
						roamPrintersArray.push(printer)
					}
				})
			}
		})
		paPrintersDispatch({
			type: SET_PA_PRINTERS,
			payload: {
				printers: paPrintersArray,
			},
		})

		roamPrintersDispatch({
			type: SET_ROAM_PRINTERS,
			payload: {
				printers: roamPrintersArray,
			},
		})
	}

	useEffect(() => {
		splitPrintAnywhereAndRoamPrinters()

		//TODO: mockedPrinters will be replaced with userPrinters
	}, [userPrinters])
	return (
		<Content>
			<JarvisPortalTemplate
				title={null}
				description={null}
				direction='ltr'
				fullWidth
				data-testid='ecp-portal-template'
				mainContent={
					<>
						<DeviceTitleSection data-testid='device-title-section'>
							<DeviceTitle data-testid='device-title'>
								{props.t
									? props.t('remotePrinting.title', 'Remote Printing')
									: getText('remotePrinting.title')}
							</DeviceTitle>
							<DeviceSubTitle data-testid='device-subtitle'>
								{user.roamQueueEnabled
									? props.t
										? props.t(
												'remotePrinting.tabbedUisubTitle',
												'Print from anywhere, on any device, and keep your prints confidential with Private Pickup.',
										  )
										: getText('remotePrinting.tabbedUisubTitle')
									: props.t
									? props.t(
											'remotePrinting.noTabSubTitle',
											'Print to any web-connected printer any time, from any location by enabling Print Anywhere. Turn on Private Pickup to ensure that a print job gets released only when the sender is near the selected printer.',
									  )
									: getText('remotePrinting.noTabSubTitle')}
							</DeviceSubTitle>
						</DeviceTitleSection>

						<Tabs
							controlId='extended'
							mode='extended'
							onChangeTab={onChangeTab}
							selectedTabId={selectedTabId}
							tabs={[
								{
									id: 0,
									label: props.t
										? props.t('printAnywhereTab.Title', 'Print Anywhere')
										: getText('printAnywhereTab.Title'),
									content: printAnywhereContent,
								},
								// {
								// 	id: 1,
								// 	label: !user.roamQueueEnabled ? (
								// 		<span className='visible' style={{ color: '#adadad' }}>
								// 			{props.t
								// 				? props.t('roamTab.Title', 'Roam')
								// 				: getText('roamTab.Title')}
								// 		</span>
								// 	) : (
								// 		<span>
								// 			{props.t
								// 				? props.t('roamTab.Title', 'Roam')
								// 				: getText('roamTab.Title')}
								// 		</span>
								// 	),
								// 	content: roamContent,
								// },
							]}
						/>
					</>
				}
			/>
		</Content>
	)
}
