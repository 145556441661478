import React, { useReducer } from 'react'
import {ToastProvider} from '@veneer/core'
import { RemotePrinting } from '../RemotePrinting'
import { setProps } from '../../utils/commonMethods'
import {
	userReducer,
	printersReducer,
	paPrintersReducer,
	roamPrintersReducer,
} from '../../reducer'
import AppContext from '../../context/AppContext'

export const RootComponent = (props) => {
	const USER_INITIAL_STATE = {
		nonOwner: false,
		roamQueueEnabled: false,
	}
	const PRINTERS_INITIAL_STATE = {
		loading: false,
		printers: [],
		error: null,
	}
	const PA_PRINTERS_INITIAL_STATE = {
		loading: false,
		printers: [],
		error: null,
		featuresLoading: {},
	}
	const ROAM_PRINTERS_INITIAL_STATE = {
		loading: false,
		printers: [],
		error: null,
		featuresLoading: {},
	}
	const [user, userDispatch] = useReducer(userReducer, USER_INITIAL_STATE)
	const [userPrinters, userPrintersDispatch] = useReducer(
		printersReducer,
		PRINTERS_INITIAL_STATE,
	)
	const [paPrinters, paPrintersDispatch] = useReducer(
		paPrintersReducer,
		PA_PRINTERS_INITIAL_STATE,
	)
	const [roamPrinters, roamPrintersDispatch] = useReducer(
		roamPrintersReducer,
		ROAM_PRINTERS_INITIAL_STATE,
	)
	setProps(props)
	return (
		<ToastProvider>
			<AppContext.Provider
				value={{
					user,
					userDispatch,
					userPrinters,
					userPrintersDispatch,
					paPrinters,
					paPrintersDispatch,
					roamPrinters,
					roamPrintersDispatch,
				}}
			>
				<RemotePrinting {...props} />
			</AppContext.Provider>
		</ToastProvider>
	)
}
