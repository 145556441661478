import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const LastSynced = styled.span.attrs((props) => ({
	'data-testid': props.testid,
}))`
	font-size: ${tokens.typography.size2};
	line-height: ${tokens.typography.lineHeight1};
	align-items: center;
	color: ${tokens.color.gray5};
	margin: 8px;
`
